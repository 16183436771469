<template>
  <div class="page widget-create">
    <b-card no-body class="card-custom mb-3">
      <template #header>
        <h6 class="mb-0 pt-1">Create new widget</h6>
      </template>
      <b-card-text class="p-4">
        <WidgetForm/>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import WidgetForm from './../../components/Widget/Widget.Form';
export default {
    props: ['user'],
    components: {
      WidgetForm
    },

    mounted(){

    }
};
</script>
