<template>
    <form v-if="form" @submit.stop.prevent="form.onSubmit" class="form-xl">
        <b-alert variant="danger" :show="form.hasError('defaultError')">{{ form.errors.defaultError }}</b-alert>
        <NotificationSuccess v-if="form.isValid" :show="form.isValid" :message="form.success.message"/>

        <slot></slot>

        <slot name="buttons-before"></slot>
        <slot v-if="!hideButtons" name="buttons">
            <button type="submit" class="btn btn-xl btn-primary" :disabled="form.submitButton.disabled">
                <b-spinner small v-if="form.submitButton.disabled"></b-spinner>
                <span class="sr-only" v-if="form.submitButton.disabled">Loading...</span>
                <span  v-if="!form.submitButton.disabled">
                    <slot name="button.save.text">
                      Save
                    </slot>
                </span>
            </button>
        </slot>
        <slot name="buttons-after"></slot>
    </form>
</template>

<script>
import NotificationSuccess from './Notification.Success';
export default {
    name: 'Form.Form.Default',
    props: ['form','hideButtons'],
    components: {
        NotificationSuccess
    }
};
</script>
