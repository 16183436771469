<template>
  <FormTemplate v-if="form" :form="form">
      <div class="row">
          <div class="col-lg-12">
              <b-form-group label="Name" label-for="inputName" description="Only for internal identification">
                  <b-form-input
                      type="text" id="inputName" class="form-control form-control-xl"
                      placeholder="enter widget name"
                      v-model="form.data.name"
                      :state="form.states.name"
                  />
                  <b-form-invalid-feedback v-if="form.errors">{{ form.errors.name }}</b-form-invalid-feedback>
              </b-form-group>
          </div>
          <div class="col-lg-6">
              <b-form-group label="Channel" label-for="inputChannel" description="Your desired channel">
                  <b-form-select v-model="form.data.channelId" :state="form.states.channelId" :options="channels" id="inputChannel"></b-form-select>
                  <b-form-invalid-feedback v-if="form.errors">{{ form.errors.channelId }}</b-form-invalid-feedback>
              </b-form-group>
          </div>
          <div class="col-lg-6">
              <b-form-group label="Language" label-for="inputLanguage" description="The language is automatically detected but if you choose one, it will be always used.">
                  <b-form-select v-model="form.data.locale" :state="form.states.locale" :options="languages" id="inputLanguage"></b-form-select>
                  <b-form-invalid-feedback v-if="form.errors">{{ form.errors.locale }}</b-form-invalid-feedback>
              </b-form-group>
          </div>
      </div>
      <template v-if="!widget" v-slot:button.save.text>Create</template>
      <template v-if="widget" v-slot:buttons-after>
        <b-button v-if="user.hasPermission('rtc:widget.delete')" class="ml-3" variant="danger" v-b-modal.modal-confirm-delete>Delete</b-button>
        <b-modal id="modal-confirm-delete" title="Delete widget" @ok="deleteWidget()">
            <p class="my-4">Are you sure that you want to delete this widget?</p>
        </b-modal>
      </template>
  </FormTemplate>
</template>

<script>
import {Form} from '@/common/utils/parnekt';
import FormTemplate from '@/common/components/Form/Form';
export default {
  props: ['widget','user'],
  components: {
    FormTemplate
  },
  data(){
      return {
        form: null,
        languages: [
          {value: "auto", text: "Automatically detected"},
          {value: "en", text: "English"},
          {value: "de", text: "Deutsch"},
        ]
      };
  },
  methods: {
    deleteWidget(){
      this.$api.delete(`widget/${this.widget.id}`).then(() => {
        this.$router.push({name: "widgets"});
      });
    }
  },

  mounted(){
    this.form = new Form({
        name: this.widget ? this.widget.name : "",
        channelId: this.widget ? this.widget.channelId : "",
        locale: this.widget ? this.widget.locale : "",
    },{
        onSubmit: (form) => {
          if(this.widget){
            return this.$api.put(`widget/${this.widget.id}`,{
              data: {
                name: form.get("name"),
                channelId: form.get("channelId"),
                locale: form.get("locale"),
              }
            });
          }
          else{
            return this.$api.post('widget',{
              data: {
                name: form.get("name"),
                channelId: form.get("channelId"),
                locale: form.get("locale"),
                widgetName: "Bubble"
              }
            })
            .then((res) => {
              this.$router.push({name: "widgets.widget.edit", params: {widgetId: res.data.id}})
            });
          }
        },
        onError: function(form, err){
            console.log(err);
        }
    });
  },

  computed: {
    channels(){
      return this.$store.getters['Callcenter/getChannels'].map(item => {
        return {value: item.id,text: item.name};
      })
    }
  }
};
</script>
