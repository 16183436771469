export default class Form{

    constructor(data, events){
        this.data = data;
        this.states = this.setStates(data);
        this.events = events;
        this.errors = {};
        this.isValid = false;
        this.success = {};

        this.submitButton = {
            disabled: false
        };
    }

    setStates(data){
        var states = {};
        for(let item in data){
            states[item] = (typeof data[item] === 'object' ? this.setStates(data[item]) : null);
        }
        return states;
    }

    get(name){
        if(name === undefined){
            return this.data;
        }

        return this.data[name] || null;
    }

    set(name,value){
      this.data[name] = value;
    }

    parseOnSuccess(res){
        if(!res){
            return false;
        }

        console.log('parseOnSuccess',res);
        this.submitButton.disabled = false;
        this.isValid = true;
        this.success  = {
            message: res.message
        };
        if(this.events['onSuccess'] && typeof this.events['onSuccess'] === "function"){
            return this.events['onSuccess'](this, res);
        }
    }

    parseOnError(res){
        if(!res){
            return false;
        }
        console.log('parseOnError',res);

        this.submitButton.disabled = false;
        this.isValid = false;

        if(res.data !== undefined && res.data.errors !== undefined){

            this.errors = res.data.errors;

            Object.keys(this.errors).map(name => {
                this.states[name] = false;
            });
        }

        if(this.events['onError'] && typeof this.events['onError'] === "function"){
            return this.events['onError'](this, res);
        }
    }

    onSubmit(e){
        e.preventDefault();
        this.errors = {};
        this.states = this.setStates(this.data);

        this.submitButton.disabled = true;
        this.isValid = false;
        return this.events['onSubmit'](this)
            .then(res => {return this.parseOnSuccess(res)})
            .catch(res => {return this.parseOnError(res.response)});
    }

    resetData(){
        for(let name in this.data){
            this.data[name] = "";
        }
        this.setStates(this.data);
    }

    hasErrors(){
        return Object.keys(this.errors).length > 0;
    }

    hasError(name){
        if(!this.hasErrors()){
            return false;
        }

        return this.errors[name] ? true : false;
    }

    addError(name,message){
      this.errors[name] = message;
      this.states[name] = false
    }
}
