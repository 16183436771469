<template>
    <b-alert 
        variant="success" 
        :show="show ? dismissSeconds : 0" 
        fade
    >
        {{ message }}
    </b-alert>
</template>

<script>
export default {
    name: 'Form.Notification.Success',
    props: ['show','message'],
    data(){
        return {
            dismissSeconds: 1
        };
    }
};
</script>

